(function (d) {
  'use strict'
  const head = d.getElementsByTagName('head')[0]
  const el = d.createElement('link')
  el.rel = 'stylesheet'
  // const que = 'family=Lora|Open+Sans:400,400i,500,500i,600,600i'
  const query = 'family=Lora%7COpen+Sans:400,400i,500,500i,600,600i'
  // el.href = 'https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:400,400i,500,500i,600,600i'
  // el.href = 'https://fonts.googleapis.com/css?' + encodeURIComponent(query)
  el.href = 'https://fonts.googleapis.com/css?' + query
  // console.log(encodeURIComponent(que))
  el.media = 'screen'
  head.appendChild(el)
  setTimeout(() => {
    el.media = 'all'
  })
}(document))

/*
function(e){var t=e.createElement("link"),a=e.getElementsByTagName("head")[0];t.rel="stylesheet",t.href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,500,500i,600,600i",t.media="screen",a.appendChild(t),setTimeout(function(){t.media="all"})}(document)
*/
